<template>
  <div id="LiveRoom">
    <div class="banner">
      <div class="liveMainArea" id="liveMainArea" ref="liveMainArea">
        <img src="@/assets/img/live/liveRoom.png" alt="" />
        <div class="v-icon" @click="startLive"></div>
        <div class="text">
          {{
            this.liveDetail.status &&
            (this.liveDetail.status.value == 0 ||
              this.liveDetail.status.value == 2)
              ? "点击播放直播预告"
              : "点击前往直播间"
          }}
        </div>
      </div>
    </div>

    <div class="infoArea">
      <div class="detail-title">直播简介</div>
      <div class="infoText">
        北美求职，秋招春招，公司情况，刷题相关问题，均可在讨论区提问讨论
        <br />
        Edward老师在直播时会解答讨论区内相关问题，面经题请在面经板块发表，请大家踊跃发言～欢迎提问和讨论～
      </div>
    </div>
    <div class="infoArea">
      <div class="detail-title">北美面经直播</div>
      <div class="infoText">
        请大家把不会的面经题，或自己面试后的面经，发布到我们平台，每周我们会挑选30·50道题目进行直播，按公司分类，讲解题目答案思路和核心代码，当然，大家有关于北美求职相关问题，刷题方面疑惑我们也会在直播时进行解答
      </div>
    </div>
    <div class="liveDescription">
      <div class="detail-title">直播信息</div>
      <div class="detail">
        <div>直播主讲：<span>Edward Shi</span></div>
        <div>直播时长：<span>60-90分钟</span></div>
        <div>直播时间：<span>每周直播，提前3天邮件通知</span></div>
        <div>直播面经数量：<span>30-50道</span></div>
      </div>
      <div class="tips">Tips：面经题目提供越多，开播越早</div>
    </div>
    <Comments :type="4" :hidden-title="true"></Comments>
    <Reply v-if="userInfo && userInfo.id" :type="4" :comment-total="0"></Reply>
  </div>
</template>
<script>
import { getLiveDetail } from "@/service/live";
import Reply from "@/components/Reply";
import Comments from "@/components/Comments";
import { mapState } from "vuex";
export default {
  name: "LiveRoom",
  components: { Reply, Comments },
  data() {
    return {
      liveDetail: {},
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      vId: "5c1d111f63618bac2bf44e31bf1faa8a_5",
      player: null,
    };
  },
  mounted() {
    this.getLiveDetail();
  },
  methods: {
    getLiveDetail() {
      getLiveDetail().then((res) => {
        if (res.success) {
          this.liveDetail = res.result;
          if (
            this.liveDetail.status &&
            (this.liveDetail.status.value == 0 ||
              this.liveDetail.status.value == 2)
          ) {
            this.$refs.liveMainArea.innerHTML = "";
            this.$nextTick(() => {
              this.initVideo();
            });
          }
        }
      });
    },
    initVideo() {
      this.$nextTick(() => {
        this.loadPlayerScript(this.getInfos);
      });
    },
    getInfos() {
      setTimeout(() => {
        this.loadPlayer();
      }, 300);
    },
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },
    loadPlayer() {
      if (this.player) {
        this.player.destroy();
      }
      const polyvPlayer = window.polyvPlayer;
      this.player = polyvPlayer({
        wrap: "#liveMainArea",
        width: "100%",
        height: 196,
        vid: this.vId,
        df: 3,
        autoplay: false,
      });
    },
    startLive() {
      if (
        this.liveDetail.status &&
        (this.liveDetail.status.value == 0 || this.liveDetail.status.value == 2)
      ) {
        // this.videoVisible = true;
        this.$refs.liveMainArea.innerHTML = "";
        this.$nextTick(() => {
          this.initVideo();
        });
      } else if (this.liveDetail.status && this.liveDetail.status.value == 1) {
        this.$router.push(`/livePage?roomNumber=${this.liveDetail.polyvId}`);
      }
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>
<style lang="scss" scoped>
#LiveRoom {
  padding-top: 54px;
  background: #f8f8f8;
}
.v-icon {
  background: url(../../assets/img/live/play.png) center no-repeat;
  width: 60px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  animation: change 0.71s ease-in-out infinite alternate;
  z-index: 2;
  cursor: pointer;
  border-radius: 50%;
}
.banner {
  width: calc(100% - 20px);
  margin: 10px;
  border-radius: 4px;
  overflow: hidden;
}
#liveMainArea {
  width: 100%;
  position: relative;
  img {
    width: 100%;
    vertical-align: top;
  }
  .text {
    position: absolute;
    left: 50%;
    bottom: 20%;
    transform: translateX(-50%);

    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 12px;
  }
}

.detail-title {
  position: relative;
  padding-left: 16px;
  font-size: 16px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #34495e;
  line-height: 24px;
  margin-bottom: 20px;
  text-align: left;
}

.detail-title::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 4px;
  height: 24px;
  background: #fa6400;
  border-radius: 2px;
}
.infoArea {
  width: calc(100% - 60px);
  margin: 10px;
  padding: 20px;
  background: #fff;
  .infoText {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #34495e;
    line-height: 20px;
  }
}
.liveDescription {
  padding: 20px !important;
  width: calc(100% - 60px);
  margin: 10px;
  background: #fff;
  text-align: left;
  .detail {
    text-align: left;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(52, 73, 94, 0.7);
    line-height: 21px;
    padding: 0 30px;
    span {
      color: #0075f6;
    }
  }
  .tips {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #34495e;
    padding: 0 30px;
    margin-top: 12px;
    line-height: 24px;
  }
}
</style>