import request from "@/utils/request";

export async function getLiveContentList(params) {
    try {
        return await request({
            url: `/posts/interview`,
            method: 'get',
            params
        });
    } catch (error) {
        return {};
    }
}

export async function getLiveDetail() {
    try {
        return await request({
            url: `/liveStreaming`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}